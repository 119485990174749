// based on https://developers.google.com/web/fundamentals/design-and-ux/input/touch#implement-custom-gestures

import {RefObject, useCallback, useEffect, useRef} from "react";
import {useStateRef} from "@web2/react_utils";

const CLICK_PREVENTION_TIMEOUT_MS = 50; // when swipe is detected, we should block click events for some time
const DEADBAND = 10; // moves below this are considered 'not moving'

export enum GestureDirection {
    LEFT = 1,
    NONE = 0,
    RIGHT = -1
}

export const useSliderTouchEvents = <T extends HTMLElement | undefined>(
    ref: RefObject<T>,
    onGestureStart: (width?: number) => void,
    onGestureEnd: (gestureDirection: GestureDirection) => void,
    isMobile: boolean | null
) => {
    const firstTouchPosition = useRef<number>(0);
    const lastTouchPositionRef = useRef<number>(0);

    // Ref below marks that we are currently waiting for the Request Animation Frame to execute. Used to debounce animations to a maximum of 60fps or whenever new animation frame executes
    const rafPendingRef = useRef(false);
    const clickPreventionTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [shouldBlockEvents, setShouldBlockEvents, shouldBlockEventsRef] = useStateRef(false);
    const gestureDirectionRef = useRef<GestureDirection>(GestureDirection.NONE);
    const isSwipeDetectedRef = useRef(false);

    /*
     * define event handlers
     */

    // start gesture
    const handleGestureStart = useCallback((event: TouchEvent | PointerEvent | MouseEvent) => {
        event.preventDefault();
        if ((event as TouchEvent).touches?.length > 1) {
            return;
        }

        onGestureStart((event.currentTarget as T)?.clientWidth);
        firstTouchPosition.current = getGesturePosFromEvent(event);

        // reset new gesture
        lastTouchPositionRef.current = getGesturePosFromEvent(event);
        isSwipeDetectedRef.current = true;

        // prevent click actions during gestures
        clickPreventionTimeoutRef.current = setTimeout(() => setShouldBlockEvents(true), CLICK_PREVENTION_TIMEOUT_MS);
    }, []);

    // move gesture
    const handleGestureMove = useCallback((event: TouchEvent | PointerEvent | MouseEvent) => {
        event.preventDefault();

        if (rafPendingRef.current) {
            // wait for next animation frame
            return;
        }

        lastTouchPositionRef.current = getGesturePosFromEvent(event);

        const differenceInX =
            Number.isFinite(firstTouchPosition.current) && lastTouchPositionRef.current ? firstTouchPosition.current - lastTouchPositionRef.current : 0;

        gestureDirectionRef.current =
            differenceInX > DEADBAND ? GestureDirection.LEFT : Math.abs(differenceInX) < DEADBAND ? GestureDirection.NONE : GestureDirection.RIGHT;
        if (isSwipeDetectedRef.current && gestureDirectionRef.current) {
            onGestureEnd(gestureDirectionRef.current);
            gestureDirectionRef.current = GestureDirection.NONE;
            isSwipeDetectedRef.current = false;
        }
    }, []);

    // end gesture
    const handleGestureEnd = useCallback((event: TouchEvent | PointerEvent | MouseEvent) => {
        event.preventDefault();

        if ((event as TouchEvent).touches && (event as TouchEvent).touches.length > 0) {
            return;
        }

        clickPreventionTimeoutRef.current && clearTimeout(clickPreventionTimeoutRef.current);
        setShouldBlockEvents(false);
    }, []);

    /*
     * attach/detach event listeners
     */
    useEffect(() => {
        if (!ref.current || !isMobile) {
            return;
        }
        // Check if pointer events are supported.
        if (window.PointerEvent) {
            // Add Pointer Event Listener
            ref.current.addEventListener("pointerdown", handleGestureStart, true);
            ref.current.addEventListener("pointermove", handleGestureMove, true);
            ref.current.addEventListener("pointerup", handleGestureEnd, true);
            ref.current.addEventListener("pointercancel", handleGestureEnd, true);
        } else {
            // Add Touch Listener
            ref.current.addEventListener("touchstart", handleGestureStart, true);
            ref.current.addEventListener("touchmove", handleGestureMove, true);
            ref.current.addEventListener("touchend", handleGestureEnd, true);
            ref.current.addEventListener("touchcancel", handleGestureEnd, true);
            // Add Mouse Listener
            ref.current.addEventListener("mousedown", handleGestureStart, true);
        }

        return () => {
            if (!ref.current || !isMobile) {
                return;
            }

            // unmount event cleanup
            if (window.PointerEvent) {
                ref.current.removeEventListener("pointerdown", handleGestureStart, true);
                ref.current.removeEventListener("pointermove", handleGestureMove, true);
                ref.current.removeEventListener("pointerup", handleGestureEnd, true);
                ref.current.removeEventListener("pointercancel", handleGestureEnd, true);
            } else {
                ref.current.removeEventListener("touchstart", handleGestureStart, true);
                ref.current.removeEventListener("touchmove", handleGestureMove, true);
                ref.current.removeEventListener("touchend", handleGestureEnd, true);
                ref.current.removeEventListener("touchcancel", handleGestureEnd, true);

                ref.current.removeEventListener("mousedown", handleGestureStart, true);
            }
        };
    }, [isMobile]);

    return {shouldBlockEventsRef};
};

export function getGesturePosFromEvent(e: TouchEvent | MouseEvent | PointerEvent) {
    let xPos: number;

    if ((e as TouchEvent).targetTouches) {
        xPos = (e as TouchEvent).targetTouches[0].clientX;
    } else {
        xPos = (e as MouseEvent | PointerEvent).clientX;
    }
    return xPos;
}
